export const enum PlacePriceStatus {
  ENABLED = 1,
  DISABLED = 2,
}

export const enum PublisherStatus {
  "NOT_AUTHORIZED" = 0,
  "AUTHORIZED_IN_BOT" = 1,
  "REGISTERED_EMAIL" = 2,
  "VERIFICATED_EMAIL" = 3,
  "OK" = 4,
}

export const enum PublicationPriceType {
  circle15s = "Круг 15с",
  circle30s = "Круг 30с",
  circle60s = "Круг 60с",
  fixedInTop = "Закреп",
  integration = "Интеграция",
  nonstandard = "Нестандартный",
  post24h = "Пост 24ч",
  post48h = "Пост 48ч",
  post72h = "Пост 72ч",
  post30d = "Пост 30 дней",
  native = "Натив",
  postWithoutDelete = "Пост без удаления",
  repost = "Репост",
  story = "Сторис",
  stream = "Стрим",
  vkMarket = "ВК Маркет",
}

export const enum PublicationFormat {
  post24h = "post24h",
  post48h = "post48h",
  post72h = "post72h",
  post30d = "post30d",
  native = "native",
  story = "story",
  fixedInTop = "fixedInTop",
  postWithoutDelete = "postWithoutDelete",
  integration = "integration",
  stream = "stream",
  circle15s = "circle15s",
  circle30s = "circle30s",
  circle60s = "circle60s",
  nonstandard = "nonstandard",
  repost = "repost",
  vkMarket = "vkMarket",
}

export enum PlaceStatus {
  ENABLED = 1,
  DISABED = 2,
  BANNED = 3,
  ARCHIVE = 4,
  DELETED = 5,
  ERROR = 200,
  DECLINED = 100,
  MODERATION_WAIT = 6,
}

export type PlacePrice = {
  name: string;
  price: number;
  priceType: string;
  tradeOfferId: number;
};

type PlaceCommon = {
  name: string;
  url: string;
  price: PlacePrice;
};

export type PlaceCountry = {
  location: string;
  value: number | null;
};

export type PlaceStat = {
  subscribersStatistic: {
    count: number | null;
    malePercent: number;
    monthGrow: number | null;
  };
  viewsStatistic: {
    countLast24h: number | null;
  };
  postsStatistic: {
    countLast24h: number | null;
  };
  dateTime: {
    date: string;
  };
};

export type PlaceStatItemValue = {
  value: number;
  dateTime: string;
};

export type PlaceStatItem = {
  series: number[];
  dates: string[];
};

export type PlaceStatReduced = {
  posts24h: PlaceStatItem;
  subscribers: PlaceStatItem;
  views24h: PlaceStatItem;
  views48h: PlaceStatItem;
  views72h: PlaceStatItem;
};

export type ReducedPlace = PlaceCommon & {
  id: number;
  categories: { id: number; name: string }[];
  er: number;
  subscribersStatistic: {
    count: number;
    monthGrow: number;
    malePercent: number;
    dayGrow: number;
    seventyTwoHourGrow: number;
    countries: PlaceCountry[];
    age: { ageRange: string; value: string | null }[] | null;
    stats: {
      dateTime: string;
      value: number;
    }[];
  };
  viewsStatistic: {
    countLast24h: number | null;
    countLast48h: number | null;
    countLast72h: number | null;
  };
  postsStatistic: {
    countLast24h: number | null;
    countLast48h: number | null;
    countLast72h: number | null;
    countLastMonth: number | null;
    countLastWeek: number | null;
  };
  avatar: string;
  cpm: number;
  clicksCount: number;
  description: string;
  dataUpdatedAt: string;
  userId: number | null;
  prices: PlacePrice[];
  date?: string[];
  time?: string;
};

export interface Place {
  id: number;
  private: boolean;
  contact: string;
  status: PlaceStatus;
  publisherComment: string;
  url: string;
}

export type ExistingPlace = Place & {
  id: number;
  newPublicationsCount: number;
  ownerUser: {
    id: number;
  } | null;
  slug?: string;
  reason?: string | null;
  interests?: number[];
  publisherComment?: string;
  categories?: {
    name: any;
    id: number;
  }[];
  interestsIds?: number[];
  categoriesIds?: number[];
  prices?: { priceType: string; price: number }[];
  costs?: { priceType: string; price: number }[];
  url: string;
  name: string;
};

export type FullPlace = ExistingPlace & {
  avatar?: string;
  description?: string;
  subscribersStatistic: any;
  viewsStatistic: any;
  postsStatistic: any;
  er?: number;
  cpm?: number;
};

export enum IconColor {
  GREEN = "rgb(58, 134, 58)",
  ORANGE = "rgb(234, 138, 34)",
  LIGHT_GRAY = "#D5DCE1",
  GRAY = "#99ABBA",
  SKY = "#00B2FF",
  BRIGHT_SKY = "rgb(14, 165, 233)",
  BLACK = "rgb(74, 74, 79)",
  RED = "rgb(205, 92, 92)",
  WHITE = "rgb(255, 255, 255)",
  INDIGO = "#104870",
  SALAD = "#27C2A7",
  PINK = "#ed91f5",
  BLUE = "#49c2f5",
  LIGHT_ORANGE = "#FFB35A",
}

export type VeeValidateFunction<T = string> = (toValidate: T) => string | true;

export interface IPublisherPlaceStateToSave {
  url: string;
  name: string;
  slug?: string;
  publisherComment?: string;
  categoriesIds?: number[];
  private?: boolean;
  prices?: INewPrices[];
  costs?: INewPrices[];
  hasModeration?: boolean;
  moderateId?: number;
  newValues?: any;
  categories?: number[];
  isActive?: boolean;
  haveModerationRequests?: boolean;
  status?: string;
  id?: number;
  contact?: string;
  reason?: string;
}

export interface INewPrices {
  priceType: string;
  price: number | null;
}

export interface Creative {
  id: number;
  uuid: string;
  temporaryToken: string;
  name: string;
  advertType: string;
  social: string;
  contentType: string;
  content: {
    text: string;
    mediaItems: {
      uri: string;
      mimeType: string;
    }[];
    files: File[];
    buttons: {
      text: string;
      url: string;
    }[];
    url: string;
  };
}
